import ProductInfo from '@components/ProductInfo';
import Header from '@components/Header';
import RegisterOwnerShip from '@components/RegisterOwnership';
import SuggestApp from '@components/SuggestApp';
import Footer from '@components/Footer';
import { useEffect, useState } from 'react';
import { getAndroidOrIOS, getLongLat } from './utils';
import getProductInfoInteractor, {
	ProductInfoData,
} from '@core/interactors/get-product-info.interactor';
import './App.scss';

function App() {
	const [data, setData] = useState<null | ProductInfoData>(null);

	useEffect(() => {
		getProductInfo();
	}, []);

	const getProductInfo = async () => {
		const resultLongLat: any = await getLongLat();
		const {
			coords: { latitude, longitude },
		} = resultLongLat;
		const androidOrIos = getAndroidOrIOS();

		const location: any = `${window.location}`;
		const paramLink = `https://drxnfc.drxasia.com/tag?${
			location.split('?')[1]
		}`;

		try {
			const response = await getProductInfoInteractor({
				link: paramLink,
				long: longitude,
				lat: latitude,
				device: androidOrIos,
			});

			const { status, data } = response;

			if (status === 200) {
				setData(data.data);
			} else {
				setData(null);
			}
		} catch (error: any) {
			setData(null);
		}
	};

	return (
		<div className='App'>
			<div className='mobile-layout-wrapper'>
				<Header />

				<div className='content-wrapper'>
					<ProductInfo data={data} />
	
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default App;
