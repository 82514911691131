// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper {
  width: 100%;
  height: 73px;
  display: grid;
  border-bottom: 1px solid #EAECF0;
}
.header-wrapper img {
  margin: auto;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,gCAAA;AACD;AACI;EACI,YAAA;EACA,cAAA;AACR","sourcesContent":[".header-wrapper {\n\twidth: 100%;\n\theight: 73px;\n\tdisplay: grid;\n\tborder-bottom: 1px solid #EAECF0;\n\n    img {\n        margin: auto;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
