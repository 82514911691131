// import DRX_BRAND from "@icons/drx-logo.svg";
import NOC_BRAND from "@icons/noc-logo.svg";
import "./style.scss";

const Header = () => {
  return (
    <div className="header-wrapper">
      <img src={NOC_BRAND} alt="noc-brand" />
    </div>
  );
};

export default Header;
