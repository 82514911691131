// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
  background: #e6e6e6;
  height: 100vh;
  overflow: hidden;
}
.App .mobile-layout-wrapper {
  max-width: 420px;
  display: block;
  margin: 0px auto;
  height: 100vh;
  background: white;
}
.App .content-wrapper {
  height: calc(100vh - 73px);
  overflow-x: hidden;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACC,mBAAA;EACA,aAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;AACJ;AAEE;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".App {\n\twidth: 100%;\n  background: #e6e6e6;\n  height: 100vh;\n  overflow: hidden;\n\n  .mobile-layout-wrapper {\n    max-width: 420px;\n    display: block;\n    margin: 0px auto;\n    height: 100vh;\n    background: white;\n  }\n\n  .content-wrapper {\n    height: calc(100vh - 73px);\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
