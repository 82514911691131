export const getAndroidOrIOS = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "ios";
  }

  return "unknown";
};

export const getLongLat = () => {
  const result: any = {
    status: "error",
    coords: {
      latitude: -1,
      longitude: -1,
    },
  };
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;

          result.status = "success";
          result.coords = coords;
          resolve(result);
        },
        (err) => {
          resolve(result);
        }
      );
    } else {
      resolve(result);
    }
  });
};
