import useAxios from "../../libraries/axios";

export interface GetProductInfoDTO {
  link: string;
  lat: number;
  long: number;
  device: string;
}

export interface Image {
  id: number;
  path: string;
  mimetype: string;
  ern: string;
  created_at: number;
}
export interface ProductInfoData {
  product: {
    id: number;
    name: string;
    category: string;
    master_sku: string;
    season: string;
    size: string;
    colour: string;
    price: number;
    description: string;
    status: number;
    stock: number;
    created_at: string;
    updated_at: string;
    custom_brand?: string;
    custom_brand_title?: string;
  };
  product_unit: {
    id: number;
    product_id: number;
    serial_number: string;
    status: string;
    batch: string;
    created_at: string;
    updated_at: string;
    warehouse_id: number;
    normal_text: string;
    bold_text: string;
    is_locked: boolean;
    custom_cta_link?: string;
    custom_cta_title?: string;
  };
  images: Image[];
  is_owned: boolean;
  product_unit_image?: string;
}

export interface GetProductInfoResp {
  status: number;
  message: string;
  data: {
    data: ProductInfoData;
    status: number;
    message: string;
  };
}

const getProductInfoInteractor = (
  params: GetProductInfoDTO
): Promise<GetProductInfoResp> => {
  const path = "/product-info";
  return useAxios.post(path, params);
};

export default getProductInfoInteractor;
