export const DROPDOWN_CATEGORY_PRODUCT_OPTIONS = [
	{
		label: 'All',
		value: '',
	},
	{
		label: 'Jersey',
		value: 'Jersey',
	},
	{
		label: 'T Shirt',
		value: 'T Shirt',
	},
	{
		label: 'Polo',
		value: 'Polo',
	},
	{
		label: 'Shirt',
		value: 'Shirt',
	},
	{
		label: 'Polo Tee',
		value: 'Polo Tee',
	},
	{
		label: 'Pants',
		value: 'Pants',
	},
	{
		label: 'Socks',
		value: 'Socks',
	},
	{
		label: 'Jacket',
		value: 'Jacket',
	},
	{
		label: 'Raincoat',
		value: 'Raincoat',
	},
	{
		label: 'Hoodie',
		value: 'Hoodie',
	},
	{
		label: 'Cap',
		value: 'Cap',
	},
	{
		label: 'Bag',
		value: 'Bag',
	},
	{
		label: 'Shorts',
		value: 'Shorts',
	},
	{
		label: 'Shoes',
		value: 'Shoes',
	},
	{
		label: 'Accessories',
		value: 'Accessories',
	},
	{
		label: 'Sweater',
		value: 'Sweater',
	},
];
