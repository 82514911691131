import BOX from '@images/box.svg';
import FIND_ICON from '@icons/find.svg';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { ProductInfoData } from '@core/interactors/get-product-info.interactor';
import { FC, useMemo } from 'react';
import './style.scss';
import { DROPDOWN_CATEGORY_PRODUCT_OPTIONS } from './constant';

interface ProductInfoPropTypes {
	data: null | ProductInfoData;
}

const ProductInfo: FC<ProductInfoPropTypes> = ({ data }) => {
	const category = useMemo(() => {
		if (data) {
			const temp = DROPDOWN_CATEGORY_PRODUCT_OPTIONS.find(
				(item) => item.value === data.product.category
			);

			return temp ? temp.label : '';
		} else {
			return '';
		}
	}, [data]);

	console.log({data: data})

	return (
		<div className='product-info-wrapper'>
			{data ? (
				<div className='authenticated-badge-wrapper'>
					<div className='dot' />
					<span>Authenticated</span>
				</div>
			) : (
				<div className='authenticated-badge-wrapper not-valid'>
					<span>Unauthenticated</span>
				</div>
			)}

			{data && (
				<>
					{data?.images && data.images.length > 1 ? (
						<Slide
							slidesToScroll={1}
							slidesToShow={1}
							indicators={true}
							autoplay
							duration={1500}
						>
							{data.images.map((image, index) => (
								<img
									key={index}
									src={`${process.env.REACT_APP_API_URL}/file/${image.path}`}
									alt={`${data.product.name} Product Images`}
								/>
							))}
						</Slide>
					) : data?.images && data.images.length === 1 ? (
						// eslint-disable-next-line jsx-a11y/img-redundant-alt
						<img
							src={`${process.env.REACT_APP_API_URL}/file/${data.images[0].path}`}
							alt={`${data.product.name} Product Image`}
						/>
					) : null}

					<span className='product-name'>{data.product.name}</span>

					<div
						className='product-number-section'
						style={
							data.product_unit_image
								? {
										backgroundImage: `url(https://api.drxasia.com/file/${data.product_unit_image})`,
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center center',
								  }
								: {}
						}
					>
						{data?.product_unit_image && <div className='bg-layer' />}
						<div className='product-number-wrapper'>
							<div className='center-item'>
								<span
									className='section-title'
									style={data.product_unit_image ? { color: 'white' } : {}}
								>
									Product Number
								</span>
								<span className='product-number'>{data.product_unit.id}</span>
							</div>
						</div>
					</div>

					{data.product_unit.normal_text && data.product_unit.bold_text && (
						<div className='custom-text'>
							<span>
								{data.product_unit.normal_text} <br />
							</span>
							<span className='custom-text-name'>
								“<b>{data.product_unit.bold_text}</b>”
							</span>
						</div>
					)}

					<div className='product-detail-section'>
						<span className='section-title'>Product Details</span>

						<div className='detail-item'>
							<span className='detail-label'>Size</span>
							<span className='detail-value'>{data.product.size}</span>
						</div>
						<div className='detail-item'>
							<span className='detail-label'>Season</span>
							<span className='detail-value' style={{ color: 'black' }}>
								{data.product.season}
							</span>
						</div>
						<div className='detail-item'>
							<span className='detail-label'>Colour</span>
							<span className='detail-value'>{data.product.colour}</span>
						</div>
						<div className='detail-item'>
							<span className='detail-label'>Category</span>
							<span className='detail-value'>{category}</span>
						</div>
					</div>
				</>
			)}
			
			{data?.product.custom_brand ? (
				<div className='cta-button-container'>
					<img
						src={`${data?.product.custom_brand}`}
						alt={`${data?.product.custom_brand} Product Image`}
					/>
					<p>{data?.product.custom_brand_title}</p>
					<a href={data?.product_unit.custom_cta_link}>
						<button className='cta-button'>
							{data?.product_unit.custom_cta_title}
						</button>
					</a>
				</div>
			) : <></>}
			{!data && (
				<div className='not-found'>
					<img src={BOX} alt='not-found' />
					<h3>Cannot find your product!</h3>
					<p>
						We apologize, but we are unable to find the product you are looking
						for.
					</p>
				</div>
			)}
		</div>
	);
};

export default ProductInfo;
