import EMAIL_BTN from '@icons/email-btn.svg';
import LOCATION_BTN from '@icons/location-btn.svg';
import PHONE_BTN from '@icons/phone-btn.svg';
import './style.scss';

const Footer = () => {
	return (
		<div className='footer-wrapper'>
			<span className='section-title'>Contact us</span>

			<span className='intro-text'>Have a Problem with your Product?</span>
			<span className='description'>
				Our team is here and ready to assist you.
			</span>

			<div className='footer-item'>
				<button>
					<img src={EMAIL_BTN} alt='email-btn' />
				</button>

				<span className='item-title'>Email</span>
				<span className='item-description'>
					Our friendly team is here to help.
				</span>
				<a className='item-value' href='mailto:info@nocindonesia.id'>
				info@nocindonesia.id
				</a>
			</div>

			<div className='footer-item'>
				<button>
					<img src={LOCATION_BTN} alt='email-btn' />
				</button>

				<span className='item-title'>Office</span>
				<span className='item-description'>
					Come say hello at our office HQ.
				</span>
				<a
					className='item-value address'
					href='https://maps.app.goo.gl/1uKrmCWbeqoeDMAo8'
					target='_blank'
					rel='noreferrer'
				>
					MOS Building Flr. 17 Jl. Pintu Senayan 1 Jakarta, 10270, INDONESIA.
				</a>
			</div>

			<div className='footer-item'>
				<button>
					<img src={PHONE_BTN} alt='email-btn' />
				</button>

				<span className='item-title'>Phone</span>
				<span className='item-description'>Call us for your needs.</span>
				<a className='item-value' href='tel:0212524797'>
					+62 21 252 4797
				</a>
				<a className='item-value' href='tel:0212524791'>
					+62 21 252 4791
				</a>
			</div>

			<div className='footer-item1'>
				<a className='item-description1' href='https://nocindonesia.id/' style={{ textDecoration: 'none'}} target='_blank'>
					©2024 NOC Indonesia. All rights reserved.
				</a>
			</div>
		</div>
	);
};

export default Footer;
